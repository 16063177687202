import isEmpty from 'lodash/isEmpty'
import {
  UPGRADE_NORMAL_KEY,
  UPGRADE_SYSTEM_KEY,
  UPGRADE_CTV_KEY,
  UPGRADE_REFERRAL_KEY,
  REFERRAL_PAYMENT_STATUS,
  COLOR_RED,
  COLOR_GREEN,
  COLOR_YELLOW
} from 'src/constants/Constants'

export function calculatePricing(isElsa, packageItem) {
  let pricing = 0
  if (packageItem['price']) {
    pricing = parseFloat(packageItem['price'])
  }
  //Check Elsa
  if (isElsa) {
    if (packageItem['supplierCombo']) {
      if (packageItem['supplierPricing']) {
        let pricingSupplier = parseFloat(packageItem['supplierPricing'])
        pricing += pricingSupplier
      }
    }
  }

  return pricing
}

export function calculateDiscountIELTSPricing(pricing, discount) {
  let pricingAfterDiscount = pricing
  if (discount > 0) {
    const discountValue = Math.round((pricing * discount) / 100)
    pricingAfterDiscount = pricing - discountValue
  }

  return pricingAfterDiscount
}

export function roundVND(pricing) {
  const number = 1000
  let valuePricing = pricing
  if (pricing > number) {
    valuePricing = Math.round(pricing / number) * number
  }

  return valuePricing
}

export function calculateDiscountPricing(isElsa, discountElsa, packageItem, discount) {
  let pricing = 0
  if (packageItem['price']) {
    pricing = parseFloat(packageItem['price'])
  }
  let pricingAfterDiscount = pricing

  if (discount > 0) {
    const discountValue = Math.round((pricing * discount) / 100)
    pricingAfterDiscount = pricing - discountValue
    pricingAfterDiscount = roundVND(pricingAfterDiscount)
  }
  //Check Elsa
  let pricingElsaAfterDiscount = 0
  if (isElsa) {
    if (packageItem['supplierCombo']) {
      if (packageItem['supplierPricing']) {
        let pricingSupplier = parseFloat(packageItem['supplierPricing'])
        //
        pricingElsaAfterDiscount = pricingSupplier
        if (discountElsa > 0) {
          const discountValueElsa = Math.round((pricingSupplier * discountElsa) / 100)
          pricingElsaAfterDiscount = pricingSupplier - discountValueElsa
          pricingElsaAfterDiscount = roundVND(pricingElsaAfterDiscount)
        }
      }
    }
  }

  return pricingAfterDiscount + pricingElsaAfterDiscount
}

export function calculatePricingPerMonth(pricing, discount, month) {
  let iMonth = month
  if (month === -1) {
    iMonth = 120
  }
  const pricingAfterDiscount = calculateDiscountIELTSPricing(pricing, discount)
  let pricingPerMonth = Math.round(pricingAfterDiscount / iMonth)
  pricingPerMonth = roundVND(pricingPerMonth)

  return pricingPerMonth
}

export function calculateSaveMoneyPerMonth(packageList, packageItem, discount) {
  let title = ''
  if (packageItem.standard === true) {
    title = 'Chuẩn'
  } else {
    const packageItemStandard = packageList.find(item => item.standard === true)
    if (packageItemStandard) {
      const priceStandard = calculatePricingPerMonth(packageItemStandard.price, discount, packageItemStandard.month)
      const priceItem = calculatePricingPerMonth(packageItem.price, discount, packageItem.month)
      const percent1 = priceItem / priceStandard
      const percent2 = 1 - percent1
      const percent3 = percent2 * 100
      title = `-${Math.round(percent3)}%`
    }
  }

  return title
}

export function generatePaymentCodeSN(paymentCodeSN, packageItem, codes) {
  let codeUser = 'PD' + paymentCodeSN.slice(2)
  // let codeUser = paymentCodeSN
  let codePackage = packageItem.codePayment
  let codeDiscount = ''
  if (codes.length > 0) {
    let newCodes = codes.map(codeItem => {
      const type = codeItem.type || ''
      const newCodeItem = { ...codeItem, type }
      return newCodeItem
    })
    newCodes.sort((a, b) => (a.type > b.type ? 1 : -1))
    newCodes.forEach(codeItem => {
      const code = codeItem.codePayment
      if (codeItem.type) {
        codeDiscount = `${codeDiscount}-${code}`
      } else {
        codeDiscount = `${codeDiscount}${code}`
      }
    })
  }

  return `${codeUser}${codePackage}${codeDiscount}`
}

export function calculateDiscountPercent(codes) {
  let maxDiscount = 50
  let valueDiscount = 0
  if (codes.length > 0) {
    codes.forEach(codeItem => {
      const discountValue = parseInt(codeItem.discountValue)
      valueDiscount += discountValue
    })
    const refCode = codes.find(codeItem => codeItem.type === 'ref')
    if (refCode) {
      maxDiscount = 30
    }
  }

  return valueDiscount > maxDiscount ? maxDiscount : valueDiscount
}

export function getTitleMonthPackage(packageItem) {
  let titleMonth = ''
  if (packageItem.month < 0) {
    titleMonth = '(+Trọn đời)'
  } else {
    titleMonth = `(+${packageItem.month || 0} tháng)`
  }

  return titleMonth
}

export function getTitlePaymentType(logData) {
  let title = ''
  if (!isEmpty(logData)) {
    if (logData.type === UPGRADE_NORMAL_KEY || logData.type === UPGRADE_SYSTEM_KEY) {
      title = `Gói VIP ${logData.package}`
    } else {
      if (logData.type === UPGRADE_CTV_KEY) {
        title = `Hỗ trợ Cộng tác viên`
      } else if (logData.type === UPGRADE_REFERRAL_KEY) {
        title = `Hỗ trợ Đối tác liên kết`
      }
    }
  }

  return title
}

export function getNumberDayPaymentType(logData) {
  let title = ''
  if (!isEmpty(logData)) {
    if (logData.months) {
      const iMonths = parseInt(logData.months)
      if (iMonths === -1) {
        title = 'Trọn đời'
      } else {
        title = `${logData.months} tháng`
      }
    } else if (logData.days) {
      title = `${logData.days} ngày`
    }
  }

  return title
}

export function getTitleReferralPaymentStatus(logData) {
  let title = 'Chờ xử lý'
  if (!isEmpty(logData)) {
    if (logData.status === REFERRAL_PAYMENT_STATUS.PENDING) {
      title = 'Đang xử lý'
    } else if (logData.status === REFERRAL_PAYMENT_STATUS.FRAUD) {
      title = 'Vi phạm chính sách'
    } else if (logData.status === REFERRAL_PAYMENT_STATUS.PAYED) {
      title = 'Đã thanh toán'
    }
  }

  return title
}

export function getColorReferralPaymentStatus(logData) {
  let title = 'black'
  if (!isEmpty(logData)) {
    if (logData.status === REFERRAL_PAYMENT_STATUS.PROCESSING) {
      title = COLOR_YELLOW
    } else if (logData.status === REFERRAL_PAYMENT_STATUS.FRAUD) {
      title = COLOR_RED
    } else if (logData.status === REFERRAL_PAYMENT_STATUS.PAYED) {
      title = COLOR_GREEN
    }
  }

  return title
}
