// import { getDocumentStatusTitle, getDocumentTypeTitle } from './documentService'
// import { getTagTitleOrderType, isImportOrderType } from './orderService'
import { getTitlePaymentType, getNumberDayPaymentType } from 'src/@core/utils/paymentUtils'
import {
  PAYMENT_CODE_ACTIVITY_ACTION_TYPE,
  PAYMENT_LOG_ACTIVITY_ACTION_TYPE
  // DOCUMENT_ACTIVITY_ACTION_TYPE,
  // PRODUCT_ACTIVITY_ACTION_TYPE,
  // PRODUCT_PROJECT_ACTIVITY_ACTION_TYPE,
  // TRANSACTION_ACTIVITY_ACTION_TYPE,
  // USER_ACTIVITY_ACTION_TYPE,
  // DISCUSSION_ACTIVITY_ACTION_TYPE,
  // ORDER_ACTIVITY_ACTION_TYPE,
  // ORDER_PRODUCT_ACTIVITY_ACTION_TYPE,
  // ORDER_PRODUCT_PROJECT_ACTIVITY_ACTION_TYPE,
  // APPROVER_ACTIVITY_ACTION_TYPE,
  // DOCUMENT_STATUS_KEY,
  // DOCUMENT_INFO_VIEW_TYPE,
  // DETAIL_ACTION_TYPE,
  // TRANSACTION_TYPE,
  // COLLECTION_NAME,
  // FACEBOOK_EMOJI_TEXT,
  // COLOR_ORANGE,
  // COLOR_BLUE,
  // COLOR_GREEN,
  // COLOR_RED,
  // COLOR_CYAN,
} from 'src/constants/Constants'
// import {
//   PlusOutlined,
//   SyncOutlined,
//   DeleteOutlined,
//   CommentOutlined,
//   LikeOutlined,
//   CheckOutlined,
//   CloseOutlined,
//   ContainerOutlined,
//   ToolOutlined,
//   ShoppingOutlined,
//   BankOutlined
// } from '@ant-design/icons'

export function getMessageText(transalation, activity) {
  let messageText = ''
  switch (activity.action) {
    case PAYMENT_CODE_ACTIVITY_ACTION_TYPE.CREATE_PAYMENT_CODE: {
      messageText = `${transalation('activity_message.create_payment_code', {
        code: activity.object.discountInfo.code
      })}`
      break
    }

    case PAYMENT_CODE_ACTIVITY_ACTION_TYPE.DELETE_PAYMENT_CODE: {
      messageText = `${transalation('activity_message.delete_payment_code', {
        code: activity.object.discountInfo.code
      })}`
      break
    }

    case PAYMENT_LOG_ACTIVITY_ACTION_TYPE.CREATE_PAYMENT_LOG: {
      messageText = `${transalation('activity_message.create_payment_log', {
        package: getTitlePaymentType(activity.object),
        time: getNumberDayPaymentType(activity.object)
      })}`
      break
    }

    default: {
      messageText = ''
      break
    }
  }

  return messageText
}

export function getNotificationAvatarIcon(activity) {
  let img = ''
  switch (activity.action) {
    case PAYMENT_CODE_ACTIVITY_ACTION_TYPE.CREATE_PAYMENT_CODE:
    case PAYMENT_CODE_ACTIVITY_ACTION_TYPE.DELETE_PAYMENT_CODE:
      img = '/images/notification/voucher.png'
      break
    case PAYMENT_LOG_ACTIVITY_ACTION_TYPE.CREATE_PAYMENT_LOG:
    case PAYMENT_LOG_ACTIVITY_ACTION_TYPE.DELETE_PAYMENT_LOG:
      img = '/images/app/vipGIF.gif'
      break
    default:
      break
  }

  return img
}

export function getNotificationRouterPath(activity) {
  let path = ''
  switch (activity.action) {
    case PAYMENT_CODE_ACTIVITY_ACTION_TYPE.CREATE_PAYMENT_CODE:
    case PAYMENT_CODE_ACTIVITY_ACTION_TYPE.DELETE_PAYMENT_CODE:
      path = '/account-settings?tab=account'
      break
    case PAYMENT_LOG_ACTIVITY_ACTION_TYPE.CREATE_PAYMENT_LOG:
    case PAYMENT_LOG_ACTIVITY_ACTION_TYPE.DELETE_PAYMENT_LOG:
      path = '/account-settings?tab=account'
      break
    default:
      break
  }

  return path
}

// export function getMessageIcon(activity) {
//   const data = {}
//   const className = 'font-size-medium my-text-white-color'
//   //
//   switch (activity.action) {
//     case DOCUMENT_ACTIVITY_ACTION_TYPE.CREATE_DOCUMENT:
//     case PRODUCT_ACTIVITY_ACTION_TYPE.CREATE_PRODUCT:
//     case PRODUCT_PROJECT_ACTIVITY_ACTION_TYPE.CREATE_PRODUCT_PROJECT:
//     case TRANSACTION_ACTIVITY_ACTION_TYPE.CREATE_TRANSACTION:
//     case PRODUCT_ACTIVITY_ACTION_TYPE.ADD_TRANSACTION:
//     case PRODUCT_PROJECT_ACTIVITY_ACTION_TYPE.ADD_TRANSACTION_PRODUCT_PROJECT:
//     case USER_ACTIVITY_ACTION_TYPE.CREATE_USER:
//       data.icon = <PlusOutlined className={className} />
//       data.color = COLOR_GREEN
//       break
//     case DOCUMENT_ACTIVITY_ACTION_TYPE.UPDATE_DOCUMENT:
//     case DOCUMENT_ACTIVITY_ACTION_TYPE.CHANGE_STATUS_DOCUMENT:
//     case PRODUCT_ACTIVITY_ACTION_TYPE.UPDATE_PRODUCT:
//     case PRODUCT_PROJECT_ACTIVITY_ACTION_TYPE.UPDATE_PRODUCT_PROJECT:
//     case TRANSACTION_ACTIVITY_ACTION_TYPE.UPDATE_TRANSACTION:
//     case DISCUSSION_ACTIVITY_ACTION_TYPE.UPDATE_DISCUSSION:
//     case USER_ACTIVITY_ACTION_TYPE.UPDATE_USER:
//       data.icon = <SyncOutlined className={className} />
//       data.color = COLOR_ORANGE
//       break
//     case DOCUMENT_ACTIVITY_ACTION_TYPE.DELETE_DOCUMENT:
//     case PRODUCT_ACTIVITY_ACTION_TYPE.DELETE_PRODUCT:
//     case PRODUCT_PROJECT_ACTIVITY_ACTION_TYPE.DELETE_PRODUCT_PROJECT:
//     case TRANSACTION_ACTIVITY_ACTION_TYPE.DELETE_TRANSACTION:
//     case DISCUSSION_ACTIVITY_ACTION_TYPE.DELETE_DISCUSSION:
//     case USER_ACTIVITY_ACTION_TYPE.DELETE_USER:
//       data.icon = <DeleteOutlined className={className} />
//       data.color = COLOR_RED
//       break

//     case ORDER_ACTIVITY_ACTION_TYPE.CREATE_ORDER_SALE:
//     case ORDER_ACTIVITY_ACTION_TYPE.UPDATE_ORDER_SALE:
//       data.icon = <ContainerOutlined className={className} />
//       if (activity.action === ORDER_ACTIVITY_ACTION_TYPE.CREATE_ORDER_SALE) {
//         data.color = COLOR_BLUE
//       } else {
//         data.color = COLOR_ORANGE
//       }
//       break

//     case ORDER_ACTIVITY_ACTION_TYPE.UPDATE_STATUS_ORDER_SALE:
//     case ORDER_ACTIVITY_ACTION_TYPE.UPDATE_STATUS_ORDER_CONSTRUCTOR:
//     case ORDER_ACTIVITY_ACTION_TYPE.UPDATE_STATUS_ORDER_WAREHOUSE:
//     case ORDER_ACTIVITY_ACTION_TYPE.UPDATE_STATUS_ORDER_PURCHASING: {
//       //
//       const messageItem = activity.message[0]
//       const newStatus = messageItem.newContent.status
//       if (newStatus === DOCUMENT_STATUS_KEY.ACCEPTED) {
//         data.color = COLOR_BLUE
//         data.icon = <CheckOutlined className={className} />
//       } else {
//         data.color = COLOR_RED
//         data.icon = <CloseOutlined className={className} />
//       }
//       break
//     }

//     case ORDER_ACTIVITY_ACTION_TYPE.CREATE_ORDER_CONSTRUCTOR:
//     case ORDER_ACTIVITY_ACTION_TYPE.UPDATE_ORDER_CONSTRUCTOR:
//       data.icon = <ToolOutlined className={className} />
//       if (activity.action === ORDER_ACTIVITY_ACTION_TYPE.CREATE_ORDER_CONSTRUCTOR) {
//         data.color = COLOR_BLUE
//       } else {
//         data.color = COLOR_ORANGE
//       }
//       break

//     case ORDER_ACTIVITY_ACTION_TYPE.CREATE_ORDER_PURCHASING:
//     case ORDER_ACTIVITY_ACTION_TYPE.UPDATE_ORDER_PURCHASING:
//       data.icon = <ShoppingOutlined className={className} />
//       if (activity.action === ORDER_ACTIVITY_ACTION_TYPE.CREATE_ORDER_PURCHASING) {
//         data.color = COLOR_BLUE
//       } else {
//         data.color = COLOR_ORANGE
//       }
//       break

//     case ORDER_ACTIVITY_ACTION_TYPE.CREATE_ORDER_WAREHOUSE:
//     case ORDER_ACTIVITY_ACTION_TYPE.UPDATE_ORDER_WAREHOUSE:
//       data.icon = <BankOutlined className={className} />
//       if (activity.action === ORDER_ACTIVITY_ACTION_TYPE.CREATE_ORDER_WAREHOUSE) {
//         data.color = COLOR_BLUE
//       } else {
//         data.color = COLOR_ORANGE
//       }
//       break

//     case DISCUSSION_ACTIVITY_ACTION_TYPE.CREATE_DISCUSSION:
//       data.icon = <CommentOutlined className={className} />
//       data.color = COLOR_CYAN
//       break

//     case DISCUSSION_ACTIVITY_ACTION_TYPE.ADD_REACTION:
//       data.icon = <LikeOutlined className={className} />
//       data.color = COLOR_BLUE
//       break
//     case APPROVER_ACTIVITY_ACTION_TYPE.REQUEST_APPROVER_DOCUMENT:
//     case APPROVER_ACTIVITY_ACTION_TYPE.REQUEST_APPROVER_ORDER_PRODUCT:
//     case APPROVER_ACTIVITY_ACTION_TYPE.REQUEST_APPROVER_ORDER_PROJECT:
//       data.icon = <CheckOutlined className={className} />
//       data.color = COLOR_RED
//       break
//     default:
//       data.icon = <SyncOutlined className={className} />
//       data.color = COLOR_ORANGE
//       break
//   }

//   return data
// }

export function isRead(activity, userId) {
  const array = activity.unRead ? activity.unRead : []
  const index = array.findIndex(element => element === userId)

  return index === -1
}
