import { useEffect, useState, useRef } from 'react'

// Services
import { subscribeRealtimeUpdatedAtWithQuery, getNotificationByUserIdLimitTime } from '../notification'

//
// import {
//    readOneNotification,
//    readAllNotification,
// } from '../../services/FirebaseFunction'

// Constants
import { COLLECTION_NAME } from '../../../../constants/Constants'

export const useNotificationData = () => {
  // State
  const [notificationDataList, setNotificationDataList] = useState([])
  const [updatedNotificationData, setUpdatedNotificationData] = useState([])

  const [loadingNotificationDataList, setLoadingNotificationDataList] = useState(true)

  const [loadMoreNotificationDataList, setLoadMoreNotificationDataList] = useState(false)

  // Ref
  const notificationDataListRef = useRef()
  notificationDataListRef.current = notificationDataList
  const loadingNotificationDataListRef = useRef()
  loadingNotificationDataListRef.current = loadingNotificationDataList
  const unsubscribeNotificationUpdatedDataRef = useRef()

  //
  useEffect(() => {
    return () => {
      // Cleanup here
      clearAllSubscribe()
    }
  }, [])

  function prepareDataList(newNotifications, currentNotifications) {
    let notificationsList = []
    if (currentNotifications.length === 0) {
      notificationsList = newNotifications
    } else {
      const notificationsCopy = [...currentNotifications]
      if (notificationsCopy.length > 0) {
        const firstIndex = 0
        const firstNotification = notificationsCopy[firstIndex]

        const lastNotification = currentNotifications[currentNotifications.length - 1]
        if (lastNotification._id === firstNotification._id) {
          notificationsCopy.splice(firstIndex, 1)
        }
        notificationsList = [...notificationsCopy, ...newNotifications]
      }
    }

    //
    setNotificationDataList(notificationsList)
  }

  function prepareDataUpdated(updatedNotifications, currentNotifications) {
    const notificationsCopy = [...currentNotifications]
    const n = updatedNotifications.length
    for (let i = 0; i < n; i++) {
      const k = n - i - 1
      const notificationItem = updatedNotifications[k]

      const index = notificationsCopy.findIndex(item => item._id === notificationItem._id)
      if (index === -1) {
        notificationsCopy.unshift(notificationItem)
      } else {
        notificationsCopy[index] = notificationItem
      }
    }

    //
    setNotificationDataList(notificationsCopy)
  }

  async function getCurrentData(userId, loadingTime, onlyUnread, limit, type) {
    const notificationData = await getNotificationByUserIdLimitTime(userId, onlyUnread, type, loadingTime, limit)

    // Load more
    let loadMore = true
    if (notificationData.length < limit) {
      loadMore = false
    }
    setLoadMoreNotificationDataList(loadMore)

    //
    const notificationDataListData = notificationDataListRef.current
    prepareDataList(notificationData, notificationDataListData)

    //
    setLoadingNotificationDataList(false)
  }

  function clearCurrentData() {
    setLoadingNotificationDataList(true)
    setNotificationDataList([])
  }

  function clearUpdatedData() {
    setUpdatedNotificationData([])
  }

  function setLoadMore(loadMore) {
    setLoadMoreNotificationDataList(loadMore)
  }

  async function subscribeNotificationUpdatedAtNow(userId, fieldDate, updateList) {
    if (unsubscribeNotificationUpdatedDataRef.current) {
      unsubscribeNotificationUpdatedDataRef.current && unsubscribeNotificationUpdatedDataRef.current()
    }

    //
    const second = 1000
    const dateNow = String(new Date().getTime() + second)

    //
    const unsubscribe = await subscribeRealtimeUpdatedAtWithQuery(
      COLLECTION_NAME.NOTIFICATION,
      {
        name: 'userCheck',
        operation: 'array-contains',
        value: userId
      },
      { name: fieldDate, operation: '>=', value: dateNow },
      (notificationData, error) => {
        if (error) {
          console.log(error)
        } else if (notificationData.length > 0) {
          if (updateList) {
            const notificationDataListData = notificationDataListRef.current
            prepareDataUpdated(notificationData, notificationDataListData)
          } else {
            setUpdatedNotificationData(notificationData)
          }
        }
      }
    )
    unsubscribeNotificationUpdatedDataRef.current = unsubscribe
  }

  // eslint-disable-next-line no-unused-vars
  async function markAsRead(data, callback) {
    // const notificationId = data._id
    // readOneNotification(notificationId, async (result, error) => {
    //    callback(result, error)
    // })
  }

  // eslint-disable-next-line no-unused-vars
  async function markAllRead(callback) {
    // await readAllNotification(async (result, error) => {
    //    callback(result, error)
    // })
  }

  function clearAllSubscribe() {
    if (unsubscribeNotificationUpdatedDataRef.current) {
      unsubscribeNotificationUpdatedDataRef.current && unsubscribeNotificationUpdatedDataRef.current()
    }
  }

  return {
    notificationDataList,
    updatedNotificationData,
    loadingNotificationDataList,
    loadMoreNotificationDataList,
    actions: {
      getCurrentData,
      subscribeNotificationUpdatedAtNow,
      setLoadMore,
      clearCurrentData,
      clearUpdatedData,
      markAsRead,
      markAllRead,
      clearAllSubscribe
    }
  }
}
