import { ROUTER_PATH_NAME } from 'src/constants/Constants'
/**
 * Check for URL queries as well for matching
 * Current URL & Item Path
 *
 * @param item
 * @param activeItem
 */

export const handleURLQueries = (router, path) => {
  if (Object.keys(router.query).length && path) {
    const arr = Object.keys(router.query)

    return router.asPath.includes(path) && router.asPath.includes(router.query[arr[0]]) && path !== '/'
  }

  return false
}

export const isSupportGlobalBanner = pathName => {
  let valid = false
  if (pathName) {
    const slug = '[slug]'
    //  Check Book
    if (pathName.indexOf(ROUTER_PATH_NAME.RESULT) >= 0) {
      if (pathName.indexOf(slug) < 0) {
        valid = true
      }
    } else if (pathName.indexOf(ROUTER_PATH_NAME.PREDICTION) >= 0) {
      if (pathName.indexOf(slug) < 0) {
        valid = true
      }
      valid = true
    } else if (pathName.indexOf(ROUTER_PATH_NAME.REVIEW) >= 0) {
      if (pathName.indexOf(slug) < 0) {
        valid = true
      }
    }
  }

  return valid
}
