import React from 'react'

// ** Next Imports
// import Image from 'next/image'

// Views
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import NotificationContentItemView from './NotificationContentItemView'
// Services
import { getNotificationAvatarIcon, isRead } from 'src/@core/data/services/notificationService'
import { useTranslation } from 'next-i18next'

// Utils
import { timeStampForDate } from 'src/@core/utils/date'

function NotificationItemView(props) {
  const { t } = useTranslation()

  // Data
  function onClickItem(e, item) {
    props.onClickItem(e, item)
  }

  return (
    <div key={`unread-background-${props.item._id}`}>
      {/* <List.Item onClick={e => onClickItem(e, props.item)} className='px-3'>
        <List.Item.Meta
          className='mr-3'
          avatar={<NotificationIcon item={props.item} />}
          title={
            <NotificationContentItemView
              translation={t}
              item={props.item}
              userInfo={props.userInfo}
              isNotificationMessage={props.isNotificationMessage}
              isNotification={props.isNotification}
            />
          }
          description={timeStampForDate(t, new Date(parseInt(props.item.createdAt)), true, true)}
        />
        
      </List.Item> */}
      <Box
        className='cursor'
        sx={{
          padding: 3,
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 0
        }}
        onClick={e => onClickItem(e, props.item)}
      >
        <Box sx={{ mr: 0, display: 'flex' }}>
          <img
            style={{ marginRight: 8 }}
            width={40}
            height={40}
            src={getNotificationAvatarIcon(props.item)}
            alt='Noti icon'
          />
          <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
            <NotificationContentItemView
              translation={t}
              item={props.item}
              userInfo={props.userInfo}
              isNotificationMessage={props.isNotificationMessage}
              isNotification={props.isNotification}
            />
            <Typography variant='body2' sx={{ width: '100%', color: 'secondary' }}>
              {timeStampForDate(t, new Date(parseInt(props.item.createdAt)), true, true)}
            </Typography>
          </Box>
          {props.isNotification && !isRead(props.item, props.userInfo._id) && (
            <Box style={{ marginLeft: 8 }} className='circular-image' key={`unread-dot-${props.item._id}`}></Box>
          )}
        </Box>
      </Box>
      <Divider sx={{ mt: 0 }} />
    </div>
  )
}

export default React.memo(NotificationItemView)
