// ** React Imports
import React, { useState, useEffect, Fragment } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

import NotificationItemView from '../notification/NotificationItemView'
//  ** antd
import Skeleton from 'antd/lib/skeleton'
import Empty from 'antd/lib/empty'

// ** MUI Imports
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import MuiMenu from '@mui/material/Menu'
import MuiMenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Badge from '@mui/material/Badge'

// ** Icons Imports
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'

// ** Third Party Components

// ** Services
import { useNotificationData } from '../../../services/firestore/hooks/useNotificationData'
import { getNotificationRouterPath } from 'src/@core/data/services/notificationService'
import { useAuth } from 'src/@core/context/AuthUserContext'
import { useTranslation } from 'next-i18next'

// ** Constants
import { PARAGRAPH_SKELETON } from '../../../../constants/Constants'

// Redux
import { connect } from 'react-redux'
import { compose } from 'redux'
import { toggleLayoutLoginModal } from 'src/@core/redux/actions'

// ** Styled Menu component
const Menu = styled(MuiMenu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    width: 380,
    overflow: 'hidden',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  '& .MuiMenu-list': {
    padding: 0
  }
}))

// ** Styled MenuItem component
const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`
}))

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    border: `2px solid ${theme.palette.background.paper}`,
    backgroundColor: 'red',
    color: 'white'
  }
}))

// const StyledBadge = styled(Badge)(({ theme }) => ({
//   '& .MuiBadge-badge': {
//     border: `2px solid ${theme.palette.background.paper}`,
//     backgroundColor: 'red',
//     color: 'white'
//   }
// }))

const NotificationDropdown = props => {
  const { t } = useTranslation()

  // ** States
  const [anchorEl, setAnchorEl] = useState(null)

  // ** Hook
  const notificationDataHook = useNotificationData()
  const { loading, dbUser } = useAuth()
  const router = useRouter()

  // Paging
  const numberItem = 3

  async function getCurrentData(loadingTime) {
    if (dbUser._id) {
      notificationDataHook.actions.getCurrentData(dbUser._id, loadingTime, false, numberItem)
    }
  }

  useEffect(() => {
    let timer = null
    if (!loading && dbUser) {
      if (anchorEl !== null) {
        const loadingTime = String(new Date().getTime())
        getCurrentData(loadingTime)
      } else {
        timer = setTimeout(() => {
          notificationDataHook.actions.clearCurrentData()
        }, 500)
      }
    } else {
      props.toggleLayoutLoginModal(false)
    }

    return () => {
      console.log('Cleanup here: NotificationPanel')

      // Cleanup here
      if (timer) {
        clearTimeout(timer)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl, dbUser, loading])

  const handleDropdownOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = () => {
    setAnchorEl(null)
  }

  // eslint-disable-next-line no-unused-vars
  const handleViewAllNotification = event => {
    setAnchorEl(null)
    router.push('/notification')
  }

  function onClickItem(e, item) {
    const path = getNotificationRouterPath(item)
    router.push(path)
    //
    handleDropdownClose()
  }

  return (
    <Fragment>
      <IconButton color='inherit' aria-haspopup='true' onClick={handleDropdownOpen} aria-controls='customized-menu'>
        <StyledBadge badgeContent={0}>
          <NotificationsOutlinedIcon
            sx={{
              padding: '4px',
              borderRadius: 1,
              border: 1,
              borderColor: theme => `${theme.palette.customColors.line}`,
              boxShadow: 0.5,
              fontSize: '2.4rem',
              backgroundColor: theme => `${theme.palette.background.paper}`
            }}
          />
        </StyledBadge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem disableRipple>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Typography sx={{ fontWeight: 600 }}>Notifications</Typography>
            <Chip
              size='small'
              label='0 New'
              color='primary'
              sx={{ height: 20, fontSize: '0.75rem', fontWeight: 500, borderRadius: '10px' }}
            />
          </Box>
        </MenuItem>
        {notificationDataHook.notificationDataList.length > 0 ? (
          notificationDataHook.notificationDataList.map((item, index) => {
            return index < numberItem ? (
              /* <MenuItem onClick={handleDropdownClose}>
                <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                  <Avatar alt='Flora' src='/images/avatars/4.png' />
                  <Box sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                    <MenuItemTitle>Congratulation Flora! 🎉</MenuItemTitle>
                    <MenuItemSubtitle variant='body2'>Won the monthly best seller badge</MenuItemSubtitle>
                  </Box>
                  <Typography variant='caption' sx={{ color: 'text.disabled' }}>
                    Today
                  </Typography>
                </Box>
              </MenuItem> */
              <NotificationItemView
                item={item}
                userInfo={dbUser}
                isNotification={true}
                isNotificationMessage={true}
                onClickItem={onClickItem}
              />
            ) : null
          })
        ) : (
          <Box sx={{ p: 4 }}>
            {notificationDataHook.loadingNotificationDataList === true ? (
              <Skeleton active={false} paragraph={PARAGRAPH_SKELETON} />
            ) : (
              <Empty description={<span>{t('general.no_data_to_display')}</span>} />
            )}
          </Box>
        )}
        {/*<ScrollWrapper>
          <MenuItem onClick={handleDropdownClose}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <Avatar alt='Flora' src='/images/avatars/4.png' />
              <Box sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                <MenuItemTitle>Congratulation Flora! 🎉</MenuItemTitle>
                <MenuItemSubtitle variant='body2'>Won the monthly best seller badge</MenuItemSubtitle>
              </Box>
              <Typography variant='caption' sx={{ color: 'text.disabled' }}>
                Today
              </Typography>
            </Box>
          </MenuItem>
          <MenuItem onClick={handleDropdownClose}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <Avatar sx={{ color: 'common.white', backgroundColor: 'primary.main' }}>VU</Avatar>
              <Box sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                <MenuItemTitle>New user registered.</MenuItemTitle>
                <MenuItemSubtitle variant='body2'>5 hours ago</MenuItemSubtitle>
              </Box>
              <Typography variant='caption' sx={{ color: 'text.disabled' }}>
                Yesterday
              </Typography>
            </Box>
          </MenuItem>
          <MenuItem onClick={handleDropdownClose}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <Avatar alt='message' src='/images/avatars/5.png' />
              <Box sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                <MenuItemTitle>New message received 👋🏻</MenuItemTitle>
                <MenuItemSubtitle variant='body2'>You have 10 unread messages</MenuItemSubtitle>
              </Box>
              <Typography variant='caption' sx={{ color: 'text.disabled' }}>
                11 Aug
              </Typography>
            </Box>
          </MenuItem>
          <MenuItem onClick={handleDropdownClose}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <img width={38} height={38} alt='paypal' src='/images/misc/paypal.png' />
              <Box sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                <MenuItemTitle>Paypal</MenuItemTitle>
                <MenuItemSubtitle variant='body2'>Received Payment</MenuItemSubtitle>
              </Box>
              <Typography variant='caption' sx={{ color: 'text.disabled' }}>
                25 May
              </Typography>
            </Box>
          </MenuItem>
          <MenuItem onClick={handleDropdownClose}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <Avatar alt='order' src='/images/avatars/3.png' />
              <Box sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                <MenuItemTitle>Revised Order 📦</MenuItemTitle>
                <MenuItemSubtitle variant='body2'>New order revised from john</MenuItemSubtitle>
              </Box>
              <Typography variant='caption' sx={{ color: 'text.disabled' }}>
                19 Mar
              </Typography>
            </Box>
          </MenuItem>
          <MenuItem onClick={handleDropdownClose}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <img width={38} height={38} alt='chart' src='/images/misc/chart.png' />
              <Box sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                <MenuItemTitle>Finance report has been generated</MenuItemTitle>
                <MenuItemSubtitle variant='body2'>25 hrs ago</MenuItemSubtitle>
              </Box>
              <Typography variant='caption' sx={{ color: 'text.disabled' }}>
                27 Dec
              </Typography>
            </Box>
          </MenuItem> 

        </ScrollWrapper>*/}
        {notificationDataHook.notificationDataList.length >= numberItem && (
          <MenuItem
            disableRipple
            // sx={{ py: 3.5, borderBottom: 0, borderTop: theme => `1px solid ${theme.palette.divider}` }}
            sx={{ py: 3.5, borderBottom: 0, borderTop: 0 }}
          >
            <Button fullWidth variant='contained' onClick={handleViewAllNotification}>
              {'Xem tất cả thông báo'}
            </Button>
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  )
}

export function mapDispatchToProps(dispatch) {
  return {
    toggleLayoutLoginModal: value => dispatch(toggleLayoutLoginModal(value))
  }
}

const withConnect = connect(null, mapDispatchToProps)

export default compose(React.memo, withConnect)(NotificationDropdown)
