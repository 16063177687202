// ** Next Import
import dynamic from 'next/dynamic'
// import Script from 'next/script'

import { useRouter } from 'next/router'

// ** MUI Imports
import Box from '@mui/material/Box'

// import Link from '@mui/material/Link'
import useMediaQuery from '@mui/material/useMediaQuery'

// ** Utils
import { isSupportGlobalBanner } from 'src/@core/layouts/utils'

// ** Layout Imports
// !Do not remove this Layout import
import VerticalLayout from 'src/@core/layouts/VerticalLayout'

// ** Navigation Imports
import VerticalNavItems from 'src/navigation/vertical'

// ** Component Import
import VerticalAppBarContent from './components/vertical/AppBarContent'

// import CookieAccept from 'src/views/cookie/CookieAccept'

// ** Hook Import
import { useSettings } from 'src/@core/hooks/useSettings'
import { useAuth } from 'src/@core/context/AuthUserContext'
import { useTranslation } from 'next-i18next'

const UserLayout = ({ children }) => {
  // const UserLayout = ({ children }) => {
  const { t } = useTranslation()

  // ** Hooks
  const { settings, saveSettings } = useSettings()
  const { dbUser } = useAuth()
  const router = useRouter()

  /**
   *  The below variable will hide the current layout menu at given screen size.
   *  The menu will be accessible from the Hamburger icon only (Vertical Overlay Menu).
   *  You can change the screen size from which you want to hide the current layout menu.
   *  Please refer useMediaQuery() hook: https://mui.com/components/use-media-query/,
   *  to know more about what values can be passed to this hook.
   *  ! Do not change this value unless you know what you are doing. It can break the template.
   */
  const hidden = useMediaQuery(theme => theme.breakpoints.down('lg'))

  const handleClickUpgrade = () => {
    router.push('/pricing/')
  }

  const handleClickScroll = () => {
    router.push('/feedback/')
  }

  return (
    <Box sx={{ position: 'relative', minHeight: '100vh' }}>
      <NewsBanner />
      {/* <Script src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7252041593417182" /> */}
      <VerticalLayout
        hidden={hidden}
        settings={settings}
        saveSettings={saveSettings}
        verticalNavItems={VerticalNavItems({ translation: t, userInfo: dbUser })} // Navigation Items
        // verticalNavItems={VerticalNavItems({ translation: t, userInfo: dbUser })} // Navigation Items
        afterVerticalNavMenuContent={(
          props // AppBar Content
        ) => (
          <UpgradeBanner
            {...props}
            settings={settings}
            onClickUpgrade={handleClickUpgrade}
            onClickScroll={handleClickScroll}
          />
        )}
        verticalAppBarContent={(
          props // AppBar Content
        ) => (
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, marginTop: 4 }}>
            <VerticalAppBarContent
              hidden={hidden}
              settings={settings}
              saveSettings={saveSettings}
              toggleNavVisibility={props.toggleNavVisibility}
            />
            {isSupportGlobalBanner(router.pathname) && (
              <GlobalBanner isSupport={isSupportGlobalBanner(router.pathname)} marginTop={6} />
            )}
          </Box>
        )}
      >
        {children}
      </VerticalLayout>
      {/* <CookieAccept /> */}
    </Box>
  )
}

const GlobalBanner = dynamic(() => import('src/views/banner/GlobalBanner'), {
  ssr: false
})

const UpgradeBanner = dynamic(() => import('src/views/banner/UpgradeBanner'), {
  ssr: false
})

const NewsBanner = dynamic(() => import('src/views/banner/NewsBanner'), {
  ssr: false
})

export default UserLayout
