import React from 'react'
// Views
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
// import ViewerEditor from '../../components/common/ViewerEditor'
// Services
import { getMessageText } from 'src/@core/data/services/notificationService'

function NotificationContentItemView(props) {
  return (
    <Box
      className='container-flex-column-align-start-justify-start break-text-label'
      key={`notification-row-${props.item._id}`}
    >
      <Typography
        // sx={{ fontWeight: 'bold' }}
        key={`notification-title-${props.item._id}`}
        dangerouslySetInnerHTML={{
          __html: getMessageText(
            props.translation,
            props.item,
            props.isNotificationMessage,
            props.isNotification ? props.userInfo.userId : ''
          )
        }}
      ></Typography>
      {/* {props.item.object.content && props.item.object.contentHTML && (
            <ViewerEditor
               key={`notification-title-content-${props.item._id}`}
               value={props.item.object.content}
               attachments={props.item.object.attachments || []}
            />
         )} */}
    </Box>
  )
}

export default React.memo(NotificationContentItemView)
