import firebase_app from '../firebaseConfig'
import { getFirestore, getDocs, collection, query, where, onSnapshot, orderBy, limit } from 'firebase/firestore'

const db = getFirestore(firebase_app)

// Constants
import { COLLECTION_NAME, ACTIVITY_SORT_BY_KEY, SORT_BY_KEY } from '../../../constants/Constants'

// Get notification limit time
export const getNotificationByUserIdLimitTime = async (userId, unread, type, time, numberLimit) => {
  //
  const userColRef = collection(db, COLLECTION_NAME.NOTIFICATION)

  let q =
    unread === false
      ? query(userColRef, where('userCheck', 'array-contains', userId))
      : query(userColRef, where('unRead', 'array-contains', userId))

  //
  if (type === ACTIVITY_SORT_BY_KEY.INFORMATION || type === ACTIVITY_SORT_BY_KEY.DISCUSSION) {
    q = query(q, where('notificationType', '==', type))
  }
  q = query(q, where('createdAt', '<=', time), orderBy('createdAt', SORT_BY_KEY.DESC), limit(numberLimit))

  const notifications = []
  try {
    const docSnap = await getDocs(q)
    docSnap.forEach(doc => {
      // doc.data() is never undefined for query doc snapshots
      notifications.push(doc.data())
    })
  } catch (e) {
    console.log(e)
  }

  return notifications
}

// Realtime data -  subscribe any data by date now
export const subscribeRealtimeUpdatedAt = async (collectionName, dateTime, callback) => {
  const userColRef = collection(db, collectionName)

  // Create a query against the collection.
  const q = query(userColRef, where('updatedAt', '>=', dateTime))

  const unsubscribe = onSnapshot(
    q,
    querySnapshot => {
      const notifications = []
      querySnapshot.forEach(doc => {
        notifications.push({
          key: doc.id,
          id: doc.id,
          ...doc.data()
        })
      })
      callback(notifications, null)
    },
    error => {
      // ...
      callback(null, error)
    }
  )

  return unsubscribe
}

// Realtime data -  subscribe any data by date now and more query
export const subscribeRealtimeUpdatedAtWithQuery = async (collectionName, fieldQuery, fieldDate, callback) => {
  const userColRef = collection(db, collectionName)

  // Create a query against the collection.
  const q = query(
    userColRef,
    where(fieldQuery.name, fieldQuery.operation, fieldQuery.value),
    where(fieldDate.name, fieldDate.operation, fieldDate.value)
  )

  const unsubscribe = onSnapshot(
    q,
    querySnapshot => {
      const notifications = []
      querySnapshot.forEach(doc => {
        notifications.push({
          id: doc.id,
          ...doc.data()
        })
      })
      callback(notifications, null)
    },
    error => {
      // ...
      callback(null, error)
    }
  )

  return unsubscribe
}
