// ** React Imports
import React, { useState, useEffect, Fragment } from 'react'

// ** Next Import
import { useRouter } from 'next/router'
import Image from 'next/image'

// ** Services
import { useAuth } from 'src/@core/context/AuthUserContext'
import isEmpty from 'lodash/isEmpty'

// ** Utils
import { getObjectValueWithDefaultValue } from '../../../utils/utils'
import { isVipMember } from 'src/@core/utils/date'

// ** MUI Imports
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

// ** Icons Imports
import CogOutline from 'mdi-material-ui/CogOutline'
import LogoutVariant from 'mdi-material-ui/LogoutVariant'
import AccountOutline from 'mdi-material-ui/AccountOutline'
import History from 'mdi-material-ui/History'

// ** Modal
import { useSnackbar } from 'notistack'

// ** Services
import { getTitleByUserType } from 'src/@core/data/services/userService'
import { useTranslation } from 'next-i18next'

// Redux
import { connect } from 'react-redux'
import { compose } from 'redux'
import { toggleLayoutLoginModal } from 'src/@core/redux/actions'

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}))

const UserDropdown = props => {
  const { t } = useTranslation()

  // ** States
  const [anchorEl, setAnchorEl] = useState(null)

  // ** Hooks
  const router = useRouter()

  const { loading, dbUser, removeDeviceAccount } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    // eslint-disable-next-line
    if (!loading && !dbUser) {
    } else {
      props.toggleLayoutLoginModal(false)
    }
    // eslint-disable-next-line
  }, [dbUser, loading])

  const handleDropdownOpen = event => {
    //
    if (isEmpty(dbUser)) {
      props.toggleLayoutLoginModal(true)
      setAnchorEl(null)
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleDropdownClose = url => {
    if (url) {
      router.push(url)
    }
    setAnchorEl(null)
  }

  const handleSignOut = async () => {
    let typeError = 'error'
    let messageError = 'Đăng xuất thành công. Hẹn gặp lại bạn lần sau!'

    //
    const resultAuthentication = await removeDeviceAccount()
    if (resultAuthentication.error) {
      messageError = 'Đã có lỗi xảy ra. Vui lòng thử lại sau!'
    } else {
      typeError = 'success'
    }

    // const resultAuthentication = await signOutAccount()

    // //
    // if (resultAuthentication.error) {
    //   // const error = resultAuthentication.error
    //   // const code = error.code

    //   //
    //   typeError = 'success'
    //   messageError = 'Đã có lỗi xảy ra. Vui lòng thử lại sau!'
    // }

    enqueueSnackbar(messageError, {
      autoHideDuration: 3000,
      variant: typeError,
      anchorOrigin: { vertical: 'top', horizontal: 'right' }
    })
  }

  const styles = {
    py: 2,
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
      fontSize: '1.375rem',
      color: 'text.secondary'
    }
  }

  return (
    <Fragment>
      <Badge
        overlap='circular'
        onClick={handleDropdownOpen}
        sx={{ ml: 2, cursor: 'pointer' }}
        badgeContent={<BadgeContentSpan />}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Avatar
          alt={
            getObjectValueWithDefaultValue(dbUser, 'name', '').length > 0
              ? getObjectValueWithDefaultValue(dbUser, 'name', '')
              : 'Log in'
          }
          sx={{ width: 40, height: 40 }}
          src={
            getObjectValueWithDefaultValue(dbUser, 'avatar', '').length > 0
              ? getObjectValueWithDefaultValue(dbUser, 'avatar', '')
              : '/images/avatars/1.png'
          }
        />
      </Badge>
      {!isEmpty(dbUser) && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleDropdownClose()}
          sx={{ '& .MuiMenu-paper': { width: 230, marginTop: 4 } }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Box sx={{ pt: 2, pb: 3, px: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Badge
                overlap='circular'
                badgeContent={<BadgeContentSpan />}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              >
                <Avatar
                  alt='John Doe'
                  src={
                    getObjectValueWithDefaultValue(dbUser, 'avatar', '').length > 0
                      ? getObjectValueWithDefaultValue(dbUser, 'avatar', '')
                      : '/images/avatars/1.png'
                  }
                  sx={{ width: '2.5rem', height: '2.5rem' }}
                />
              </Badge>
              <Box sx={{ display: 'flex', marginLeft: 3, alignItems: 'flex-start', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: 600 }}>{getObjectValueWithDefaultValue(dbUser, 'name', '')}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Typography variant='body2' sx={{ fontSize: '0.8rem', color: 'text.disabled' }}>
                    {t(getTitleByUserType(getObjectValueWithDefaultValue(dbUser, 'type', '')))}
                  </Typography>
                  {isVipMember(getObjectValueWithDefaultValue(dbUser, 'expiredDatePD', '')) === true && (
                    <Image
                      style={{ marginLeft: 4 }}
                      width={30}
                      height={30}
                      src='/images/app/vipGIF.gif'
                      alt='VIP Card'
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider sx={{ mt: 0, mb: 1 }} />
          <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/ielts-review/history')}>
            <Box sx={styles}>
              <History sx={{ marginRight: 2 }} />
              {t('user.history')}
            </Box>
          </MenuItem>
          <Divider sx={{ mt: 0, mb: 1 }} />
          <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/account-settings?tab=account')}>
            <Box sx={styles}>
              <AccountOutline sx={{ marginRight: 2 }} />
              {t('user.profile')}
            </Box>
          </MenuItem>
          {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Box sx={styles}>
            <EmailOutline sx={{ marginRight: 2 }} />
            Inbox
          </Box>
        </MenuItem>
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Box sx={styles}>
            <MessageOutline sx={{ marginRight: 2 }} />
            Chat
          </Box>
        </MenuItem>
        <Divider /> */}
          <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/account-settings?tab=setting')}>
            <Box sx={styles}>
              <CogOutline sx={{ marginRight: 2 }} />
              {t('user.settings')}
            </Box>
          </MenuItem>
          {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Box sx={styles}>
            <CurrencyUsd sx={{ marginRight: 2 }} />
            Pricing
          </Box>
        </MenuItem>
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose()}>
          <Box sx={styles}>
            <HelpCircleOutline sx={{ marginRight: 2 }} />
            FAQ
          </Box>
        </MenuItem> */}
          <Divider />
          <MenuItem sx={{ py: 2 }} onClick={handleSignOut}>
            <LogoutVariant sx={{ marginRight: 2, fontSize: '1.375rem', color: 'text.secondary' }} />
            {t('user.logout')}
          </MenuItem>
        </Menu>
      )}
    </Fragment>
  )
}

export function mapDispatchToProps(dispatch) {
  return {
    toggleLayoutLoginModal: value => dispatch(toggleLayoutLoginModal(value))
  }
}

const withConnect = connect(null, mapDispatchToProps)

export default compose(React.memo, withConnect)(UserDropdown)
