/**
 * The global state selectors
 */

import { createSelector } from 'reselect'
import { initialState } from '../reducers/modalReducer'

const selectModal = state => state.modal || initialState

// Modal

const makeSelectOrderModalVisible = () => createSelector(selectModal, userState => userState.orderModalVisible)

const makeSelectOrderModalData = () => createSelector(selectModal, userState => userState.orderModalData)

const makeSelectLoginModalVisible = () => createSelector(selectModal, userState => userState.loginModalVisible)

const makeSelectVerifyEmailModalVisible = () =>
  createSelector(selectModal, userState => userState.verifyEmailModalVisible)

const makeSelectUpgradeModalVisible = () => createSelector(selectModal, userState => userState.upgradeModalVisible)

const makeSelectPaymentModalVisible = () => createSelector(selectModal, userState => userState.paymentModalVisible)

const makeSelectDeviceModalVisible = () => createSelector(selectModal, userState => userState.deviceModalVisible)

const makeSelectPaymentModalData = () => createSelector(selectModal, userState => userState.paymentModalData)

export {
  selectModal,
  makeSelectOrderModalVisible,
  makeSelectOrderModalData,
  makeSelectLoginModalVisible,
  makeSelectVerifyEmailModalVisible,
  makeSelectUpgradeModalVisible,
  makeSelectPaymentModalVisible,
  makeSelectDeviceModalVisible,
  makeSelectPaymentModalData
}
