/**
 * The global state selectors
 */
// import { useSelector, shallowEqual } from "react-redux";
import { createSelector } from 'reselect'
import { initialState } from '../reducers/deviceReducer'

const selectDevice = state => state.device || initialState

const makeSelectFingerPrint = () => createSelector(selectDevice, userState => userState.fingerPrint)
const makeSelectDeviceInfo = () => createSelector(selectDevice, userState => userState.deviceInfo)

export { selectDevice, makeSelectFingerPrint, makeSelectDeviceInfo }
