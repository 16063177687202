import IconButton from '@mui/material/IconButton'

// ** Icons Imports
import WeatherNight from 'mdi-material-ui/WeatherNight'
import WeatherSunny from 'mdi-material-ui/WeatherSunny'

const ModeToggler = props => {
  // ** Props
  const { settings, saveSettings } = props

  const handleModeChange = mode => {
    saveSettings({ ...settings, mode })
  }

  const handleModeToggle = () => {
    if (settings.mode === 'light') {
      handleModeChange('dark')
    } else {
      handleModeChange('light')
    }
  }

  return (
    <IconButton color='inherit' aria-haspopup='true' onClick={handleModeToggle}>
      {settings.mode === 'dark' ? (
        <WeatherSunny
          sx={{
            padding: '4px',
            borderRadius: 1,
            border: 1,
            borderColor: theme => `${theme.palette.customColors.line}`,
            boxShadow: 0.5,
            fontSize: '2.4rem',
            backgroundColor: theme => `${theme.palette.background.paper}`
          }}
        />
      ) : (
        <WeatherNight
          sx={{
            padding: '4px',
            borderRadius: 1,
            border: 1,
            borderColor: theme => `${theme.palette.customColors.line}`,
            boxShadow: 0.5,
            fontSize: '2.4rem',
            backgroundColor: theme => `${theme.palette.background.paper}`
          }}
        />
      )}
    </IconButton>
  )
}

export default ModeToggler
