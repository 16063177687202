// ** Icon imports
import GoogleCirclesExtended from 'mdi-material-ui/GoogleCirclesExtended'
import InformationOutline from 'mdi-material-ui/InformationOutline'
import StarBoxMultipleOutline from 'mdi-material-ui/StarBoxMultipleOutline'
import CardAccountMailOutline from 'mdi-material-ui/CardAccountMailOutline'
import ShieldSunOutline from 'mdi-material-ui/ShieldSunOutline'
import TableEye from 'mdi-material-ui/TableEye'
import CheckOutline from 'mdi-material-ui/CheckOutline'

import { ROUTER_PATH_NAME } from 'src/constants/Constants'

// eslint-disable-next-line no-unused-vars
const navigation = props => {
  const items = [
    {
      sectionTitle: 'Dự đoán',
      sectionKey: 'nav-section-main-app'
    },
    {
      title: 'Đáp án đề',
      icon: CheckOutline,
      path: ROUTER_PATH_NAME.RESULT,
      isHome: true
    },
    {
      title: 'Dự đoán đề',
      icon: TableEye,
      path: ROUTER_PATH_NAME.PREDICTION
    },
    {
      // sectionTitle: props.translation('general.community'),
      sectionTitle: 'Cộng đồng',
      sectionKey: 'nav-section-community-app'
    },
    {
      // title: props.translation('general.list'),
      title: 'Người dùng review',
      icon: GoogleCirclesExtended,
      path: ROUTER_PATH_NAME.REVIEW
    },
    {
      sectionTitle: 'Thông tin',
      sectionKey: 'nav-section-application'
    },
    {
      title: 'Về chúng tôi',
      icon: InformationOutline,
      path: '/about'
    },
    {
      title: 'Đánh giá của người dùng',
      icon: StarBoxMultipleOutline,
      path: '/feedback'
    },
    {
      title: 'Liên hệ',
      icon: CardAccountMailOutline,
      path: '/contact-us'
    },
    {
      title: 'Điều khoản dịch vụ',
      icon: ShieldSunOutline,
      path: '/private-policy'
    }
  ]

  return items
}

export default navigation
