import dynamic from 'next/dynamic'
// ** React Imports
import React, { useEffect } from 'react'
import { useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

// ** Icons Imports

// ** Theme Config Import
import themeConfig from 'src/configs/themeConfig'

// ** Components
import AppBar from './components/vertical/appBar'

//  ** Services
import { useAuth } from 'src/@core/context/AuthUserContext'
import { useSnackbar } from 'notistack'
import { useAccountData } from 'src/@core/services/firestore/hooks/useAccountData'
import { checkUpdateLastTime } from 'src/@core/data/services/userService'

//  ** Utils
import isEmpty from 'lodash/isEmpty'

// Constants
import { MAX_LENGTH, DEVICE_USER_DATA_FIELD } from 'src/constants/Constants'

// Redux
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import {
  makeSelectLoginModalVisible,
  makeSelectVerifyEmailModalVisible,
  makeSelectUpgradeModalVisible,
  makeSelectDeviceModalVisible,
  makeSelectPaymentModalVisible,
  makeSelectPaymentModalData
} from 'src/@core/redux/selectors/modalSelector'
import { makeSelectDeviceInfo } from 'src/@core/redux/selectors/deviceSelector'
import { settingDataDeviceModal } from 'src/@core/redux/actions'

const VerticalLayoutWrapper = styled('div')({
  height: '100%',
  display: 'flex'
})

const MainContentWrapper = styled(Box)({
  flexGrow: 1,
  minWidth: 0,
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column'
})

const ContentWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  padding: theme.spacing(6),
  transition: 'padding .25s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}))

const VerticalLayout = props => {
  // ** Props
  const { settings, children, scrollToTop, hidden } = props

  // ** Vars
  const { contentWidth } = settings
  const [navWidth, setNavWidth] = useState(themeConfig.navigationSize)

  // ** States
  const [navVisible, setNavVisible] = useState(false)

  const { dbUser, fingerPrint, removeDeviceAccount } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const accountDataHook = useAccountData()

  // ** Toggle Functions
  const toggleNavVisibility = () => {
    setNavVisible(!navVisible)
  }

  useEffect(() => {
    setNavWidth(hidden === false && navVisible === false ? themeConfig.navigationSmallSize : themeConfig.navigationSize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navVisible, hidden])

  useEffect(() => {
    if (!isEmpty(dbUser)) {
      if (dbUser.blocked === true) {
        handleSignOutWhenBlockAccount()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbUser])

  useEffect(() => {
    if (!isEmpty(props.deviceInfo) && !isEmpty(fingerPrint) && !isEmpty(dbUser)) {
      const fieldNameFinger = DEVICE_USER_DATA_FIELD.FINGER_PRINT
      const fieldNameDevice = DEVICE_USER_DATA_FIELD.DEVICE_INFO
      //
      let newFingerPrint = []
      let newDeviceInfo = []
      if (dbUser[fieldNameFinger]) {
        newFingerPrint = [...dbUser[fieldNameFinger]]
      }
      if (dbUser[fieldNameDevice]) {
        newDeviceInfo = [...dbUser[fieldNameDevice]]
      }
      //
      //
      const lastTime = String(new Date().getTime())
      const newDeviceInfoItem = { ...props.deviceInfo, fingerPrint, lastTime }

      let isValid = false
      //
      if (newFingerPrint.length >= MAX_LENGTH.DEVICE) {
        if (!newFingerPrint.find(item => item === fingerPrint)) {
          props.settingDataDeviceModal(true)
        } else {
          isValid = checkUpdateLastTime(newDeviceInfo, fingerPrint)
          if (isValid === true) {
            const oldIndexDeviceInfo = newDeviceInfo.findIndex(item => item[fieldNameFinger] === fingerPrint)
            newDeviceInfo[oldIndexDeviceInfo] = newDeviceInfoItem
          }
        }
      } else {
        if (!newFingerPrint.find(item => item === fingerPrint)) {
          newFingerPrint.push(fingerPrint)
          //
          newDeviceInfo.push(newDeviceInfoItem)
          //
          isValid = true
        } else {
          isValid = checkUpdateLastTime(newDeviceInfo, fingerPrint)
          if (isValid === true) {
            const oldIndexDeviceInfo = newDeviceInfo.findIndex(item => item[fieldNameFinger] === fingerPrint)
            newDeviceInfo[oldIndexDeviceInfo] = newDeviceInfoItem
          }
        }
      }
      //
      if (isValid === true) {
        const newInfo = {}
        newInfo[fieldNameFinger] = newFingerPrint
        newInfo[fieldNameDevice] = newDeviceInfo
        //
        accountDataHook.actions.updateMyAccountInfo(dbUser._id, newInfo)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.deviceInfo, fingerPrint, dbUser])

  const handleSignOutWhenBlockAccount = async () => {
    let typeError = 'error'
    let messageError = 'Tài khoản của bạn đã khoá. Liên hệ chúng tôi để được hỗ trợ!'

    //

    const resultAuthentication = await removeDeviceAccount()

    //
    if (resultAuthentication.error) {
      console.log('handleSignOutWhenBlockAccount error:', resultAuthentication.error)
    }

    enqueueSnackbar(messageError, {
      autoHideDuration: 3000,
      variant: typeError,
      anchorOrigin: { vertical: 'top', horizontal: 'right' }
    })
  }

  function handleCloseLoginModal() {}

  return (
    <>
      <VerticalLayoutWrapper className='layout-wrapper'>
        <Navigation
          navWidth={navWidth}
          navVisible={navVisible}
          setNavVisible={setNavVisible}
          toggleNavVisibility={toggleNavVisibility}
          {...props}
        />
        <MainContentWrapper className='layout-content-wrapper'>
          <AppBar toggleNavVisibility={toggleNavVisibility} {...props} />

          <ContentWrapper
            className='layout-page-content'
            sx={{
              ...(contentWidth === 'boxed' && {
                mx: 'auto',
                '@media (min-width:1440px)': { maxWidth: 1440 },
                '@media (min-width:1200px)': { maxWidth: '100%' }
              })
            }}
          >
            {children}
          </ContentWrapper>

          <Footer {...props} />

          {props.loginModalVisible === true && (
            <ModalLogin visible={props.loginModalVisible} zIndex={5000} onCancel={handleCloseLoginModal} />
          )}
          {props.verifyEmailModalVisible === true && (
            <ModalVerifyEmail visible={props.verifyEmailModalVisible} zIndex={5000} onCancel={handleCloseLoginModal} />
          )}
          {props.upgradeModalVisible === true && (
            <ModalUpgrade visible={props.upgradeModalVisible} zIndex={5000} onCancel={handleCloseLoginModal} />
          )}
          {props.deviceModalVisible === true && (
            <ModalManageDevice
              visible={props.deviceModalVisible}
              dbUser={dbUser}
              fingerPrint={fingerPrint}
              zIndex={5000}
              onCancel={handleCloseLoginModal}
            />
          )}
          {props.paymentModalVisible === true && (
            <ModalPaymentType
              visible={props.paymentModalVisible}
              itemData={props.paymentModalData}
              zIndex={5000}
              onCancel={handleCloseLoginModal}
            />
          )}
        </MainContentWrapper>
      </VerticalLayoutWrapper>

      {scrollToTop ? (
        scrollToTop(props)
      ) : (
        <ScrollToTop className='mui-fixed'>
          <Fab color='primary' size='small' aria-label='scroll back to top'>
            <ArrowUpwardIcon />
          </Fab>
        </ScrollToTop>
      )}
    </>
  )
}

const Navigation = dynamic(() => import('./components/vertical/navigation'), { ssr: false })
const Footer = dynamic(() => import('./components/shared-components/footer'), { ssr: false })

const Fab = dynamic(() => import('@mui/material/Fab'), {
  ssr: false
})

const ArrowUpwardIcon = dynamic(() => import('@mui/icons-material/ArrowUpward'), {
  ssr: false
})

const ScrollToTop = dynamic(() => import('src/@core/components/scroll-to-top'))
const ModalLogin = dynamic(() => import('src/@core/layouts/components/modal/modal_login'))
const ModalVerifyEmail = dynamic(() => import('src/@core/layouts/components/modal/modal_verify_email'))
const ModalUpgrade = dynamic(() => import('src/@core/layouts/components/modal/modal_upgrade'))
const ModalManageDevice = dynamic(() => import('src/@core/layouts/components/modal/modal_manage_device'))
const ModalPaymentType = dynamic(() => import('src/@core/layouts/components/modal/modal_payment_info'))

const mapStateToProps = createStructuredSelector({
  deviceInfo: makeSelectDeviceInfo(),
  loginModalVisible: makeSelectLoginModalVisible(),
  verifyEmailModalVisible: makeSelectVerifyEmailModalVisible(),
  upgradeModalVisible: makeSelectUpgradeModalVisible(),
  deviceModalVisible: makeSelectDeviceModalVisible(),
  paymentModalVisible: makeSelectPaymentModalVisible(),
  paymentModalData: makeSelectPaymentModalData()
})

export function mapDispatchToProps(dispatch) {
  return {
    settingDataDeviceModal: value => dispatch(settingDataDeviceModal(value))
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(React.memo, withConnect)(VerticalLayout)
